<script setup>
import { useRoute } from 'vue-router'; // Router'dan mevcut route'u almak için
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import CookieConsent from './components/CookieConsent.vue'
import WheelPopup from './components/WheelPopup.vue' 
const route = useRoute();

const noHeaderFooterRoutes = ['/checkout']; // Örnek rotalar
</script>

<template>
  <div id="app-container">
    <Header v-if="!noHeaderFooterRoutes.includes(route.path)" />
    <main>
      <router-view />
     <WheelPopup />
    </main>
    <Footer v-if="!noHeaderFooterRoutes.includes(route.path)" />
    <CookieConsent />
  </div>
</template>

<style scoped>



</style>
