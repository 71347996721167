<template>
 <div v-if="showPopup" class="popup">
    <div class="popup-content">
      <div class="wheel-section">
        <!-- Lucky Wheel -->
        <LuckyWheel
          ref="myLucky"
          width="450px"
          height="450px"
          :blocks="blocks"
          :prizes="prizes"
          :buttons="buttons"
          @start="onStart"
          @end="onEnd"
        />
      </div>
      <div class="form-section">
        <h2>Hediye Çarkı 🎁</h2>
        <p>
          Bugün OHUP Fitness spor sandalyelerinde çok hediye var! <br> E-posta adresinizi girerek
          çarkıfelek hediyelerinden faydalanın!
        </p>
        <form @submit.prevent="submitForm">
          <input
            v-model="name"
            type="text"
            placeholder="Adınız Soyadınız"
            required
          />
          <input
            v-model="email"
            type="email"
            placeholder="E-posta adresinizi giriniz."
            required
          />
          <button type="submit">Çarkı çevir, hediyeleri gör!</button>
        </form>
                <!-- Başarı ve hata mesajlarını burada göstereceğiz -->
        <div v-if="message" :class="['message', messageType]">
          {{ message }}
        </div>
        <p class="opt-out" @click="closePopup">Teşekkürler, istemiyorum.</p>
        <p class="info">
          E-posta yoluyla iletişim almak için kayıt oluyorsunuz ve istediğiniz
          zaman aboneliğinizi iptal edebilirsiniz.
        </p>
      </div>
    </div>
    <div class="popup-overlay" @click="closePopup"></div>
  </div>
  </template>
  
  <script>
  import { LuckyWheel } from '@lucky-canvas/vue'
  
  export default {
    components: {
      LuckyWheel
    },
    data() {
      return {
        // Çarkın arka plan blokları
        blocks: [{ padding: '10px', background: '#f7c46c', rotate: 'true' }],
        // Ödüller ve olasılık değerleri
        prizes: [
          { background: '#ffa100', fonts: [{ text: '1.000 TL', wordWrap: true, lineClamp: 1, lineHeight: 35, fontColor: '#fff', fontWeight: '700' }], probability: 20 },
          { background: '#000', fonts: [{ text: '2.000 TL', wordWrap: true, lineClamp: 1, lineHeight: 35, fontColor: '#fff', fontWeight: '700' }], probability: 10 },
          { background: '#ffa100', fonts: [{ text: '3.000 TL', wordWrap: true, lineClamp: 1, lineHeight: 35, fontColor: '#fff', fontWeight: '700' }], probability: 10 },
          { background: '#000', fonts: [{ text: '4.000 TL', wordWrap: true, lineClamp: 1, lineHeight: 35, fontColor: '#fff', fontWeight: '700' }], probability: 9 },
          { background: '#ffa100', fonts: [{ text: '5.000 TL', wordWrap: true, lineClamp: 1, lineHeight: 35, fontColor: '#fff', fontWeight: '700' }], probability: 5 },
          { background: '#000', fonts: [{ text: '10.000 TL', wordWrap: true, lineClamp: 1, lineHeight: 35, fontColor: '#fff', fontWeight: '700' }], probability: 1 },
          { background: '#ffa100', fonts: [{ text: 'Hediye Yok', wordWrap: true, lineClamp: 1, lineHeight: 35, fontColor: '#fff', fontWeight: '700' }], probability: 45 },
          { background: '#000', fonts: [{ text: '20.000 TL', wordWrap: true, lineClamp: 1, lineHeight: 35, fontColor: '#fff', fontWeight: '700' }], probability: 0 },

        ],
        // Butonlar
        buttons: [
          { radius: '40%', background: '#f7c46c' },
          { radius: '35%', background: '#ffa100' },
          {
            radius: '30%',
            background: '#fff',
            pointer: true,
            fonts: [{ text: 'OHUP', top: '-10px', wordWrap: true, lineClamp: 1, lineHeight: 15, fontColor: '#000', fontWeight: '700', fontSize: '22px' }]
          }
        ],
        currentPrizeIndex: null,  // Kazanan ödülün index'i
        showPopup: true,
        name: "",
        email: "",
        message: "", // Kullanıcıya gösterilecek mesaj
        messageType: "", 
        prize: "",
      }
    },
    methods: {
      // Çarkı döndürmek için fonksiyon
      spinWheel() {
        this.$refs.myLucky.play()
        // Olasılıklara göre bir ödül seçiyoruz
        const randomIndex = this.getRandomPrizeIndex()
        this.currentPrizeIndex = randomIndex  // Kazanan ödülün index'ini saklıyoruz
        setTimeout(() => {
          this.$refs.myLucky.stop(randomIndex);  // Kazanan ödül index'ine göre çarkı durduruyoruz
          const today = new Date().toISOString().split('T')[0];
          localStorage.setItem('lastSpin', today); // Çarkın çevrildiği gün kaydedilir
          localStorage.setItem('wheelSpun', 'true');
        }, 1000)
        
      },
      // Olasılık ağırlıklı rastgele ödül seçme fonksiyonu
      getRandomPrizeIndex() {
        const totalProbability = this.prizes.reduce((total, prize) => total + prize.probability, 0)
        const random = Math.random() * totalProbability
        let cumulativeProbability = 0
    
        for (let i = 0; i < this.prizes.length; i++) {
          cumulativeProbability += this.prizes[i].probability
          if (random <= cumulativeProbability) {
            return i
          }
        }
        return   // Default olarak ilk ödülü seçer
      },
      // Çark durduğunda tetiklenen fonksiyon
      onStart() {},
      onEnd() {
        // currentPrizeIndex'e göre kazanan ödülü prizes listesinden alıyoruz
        const winningPrize = this.prizes[this.currentPrizeIndex];

if (winningPrize && winningPrize.fonts && winningPrize.fonts[0]) {
  // Eğer kazanan ödül "Hediye Yok" ise özel bir mesaj göster
  if (winningPrize.fonts[0].text === "Hediye Yok") {
    this.showMessage("Şansınızı daha sonra tekrar deneyin!", "error");
  } else {
    // Diğer ödüller için kazandığı ödülü ve kupon kodunu göster
    const couponCode = `OHUP-${this.name}-${winningPrize.fonts[0].text}`.replace(/\s+/g, '-');
    this.showMessage(`${winningPrize.fonts[0].text} ödül kazandınız! Kupon kodunuz: ${this.prize}`, "success");
  }
} else {
  console.error("Kazanan ödül bilgisi alınamadı!");
}
      },
      closePopup() {
      this.showPopup = false;
        // Sayfa yüklendiğinde çarkın çevrilip çevrilmediğini kontrol et
  const today = new Date().toISOString().split('T')[0];
  localStorage.setItem('lastSpin', today);
    },
    submitForm(event) {
  // Kullanıcı bilgilerini aldıktan sonra çarkı döndür
  if (this.name && this.email) {
    // Kullanıcının daha önce çarkı çevirip çevirmediğini kontrol et
    if (localStorage.getItem('wheelSpun')) {
      event.preventDefault(); // Formun submit edilmesini durdurur
      this.showMessage(`Çarkı zaten çevirdiniz, tekrar çeviremezsiniz.`, "error");

      return; // Çıkış yaparak çarkı döndürmeyi engelle
    }

    // Çarkı döndür ve kazanan ödülü belirle
    this.spinWheel();

    // Çark döndürüldükten sonra kazanan ödül kaydedilecek
    this.$nextTick(() => {
      // Kazanan ödül bilgilerini backend'e kaydet
      const winningPrize = this.prizes[this.currentPrizeIndex].fonts[0].text;
      const userData = {
        name: this.name,
        email: this.email,
        prize: winningPrize,
        dateWon: new Date().toISOString()
      };

      // Backend'e POST isteği ile veri gönder
      fetch('https://ohupfitness.com.tr/inc/save_wheel.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          // Kullanıcının çarkı çevirdiğini localStorage'da kaydediyoruz
          localStorage.setItem('wheelSpun', 'true');
          this.prize = data.couponCode;
        } else {
          alert("Bir hata oluştu: " + data.message);
        }
      })
      .catch(error => {
        console.error('Hata:', error);
        alert('Bilgiler kaydedilemedi.');
      });
    });
  } else {
    this.showMessage(`Lütfen adınızı ve e-posta adresinizi giriniz.`, "error");

  }
},
showMessage(message, type) {
      this.message = message;
      this.messageType = type;

    },

    },
    mounted() {
  // Sayfa yüklendiğinde çarkın çevrilip çevrilmediğini kontrol et
  const lastSpin = localStorage.getItem('lastSpin');
  const today = new Date().toISOString().split('T')[0];

  if (lastSpin === today) {
    this.showPopup = false; // Eğer çark bugün çevrilmişse, popup'ı gösterme
  }
}
  }
  </script>
  
  <style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1001;
  max-width: 900px;
}

.wheel-section {
  flex: 1;
  text-align: center;
}

.form-section {
  flex: 1;
  padding-left: 20px;
}

input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  background-color: #000;
  color: white;
  border: none;
  cursor: pointer;
  width: 100%;
}

.opt-out {
  margin-top: 10px;
  color: #ff00a5;
  text-align: center;
}

.info {
  font-size: 12px;
  color: #888;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.message {
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  border-radius: 5px;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
}

@media (max-width:768px) {
  .popup{
    display: none;
  }
}
  </style>
  